import { reportWorkTypes } from "constants/layout";
import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap";
import { getBranches, getOptions } from "./optionsServices";
import "flatpickr/dist/themes/material_red.css"
import Flatpickr from "react-flatpickr"
import Timezone from "utils/timezone";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";

const initObj = {
    type: 'all',
    branch: 'all',
    dates: [moment().format("DD-MM-YYYY"), moment().format("DD-MM-YYYY")],
    client: '',
    category: '',
    producttype: '',
    product: ''
}

export const Filters = ({ 
    callback,
    fclients=true,
    fproducts=true,
    fcity=true,
    fdates=true,
    fp_categories=true,
    fp_types=true,
    fordertype=true,
}) => {
    const [branches, setBranches] = useState([]);
    const [clients, setClients] = useState([]);
    const [categories, setCategories] = useState([]);
    const [productTypes , setProductTypes] = useState([]);
    const [products , setProducts] = useState([]);
    const [cities , setCities] = useState([]);
    const [multiSelected , setMultiSelected] = useState([]);

    const [filterData, setFilterData] = useState(initObj);

    useEffect(async() => {
        // setBranches(await getBranches())
        callback(filterData)
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if(fclients){
                const clients = await getOptions(filterData.branch, 'clients');
                setClients(clients);
            }

            if(fp_categories){
                const categories = await getOptions(filterData.branch, 'categories');
                setCategories(categories);
            }

            if(fp_types){
                const productTypes = await getOptions(filterData.branch, 'productTypes');
                setProductTypes(productTypes);
            }
            
            if(fproducts){
                const products = await getOptions(filterData.branch, 'products');
                setProducts(products);
            }
            
            if(fcity){
                const Cities = await getOptions(filterData.branch, 'cities');
                setCities(Cities);
            }
        };
    
        fetchData();
    }, [filterData.branch]);    

    useEffect(async() => {
        if(filterData.category != ''){
            setProductTypes(await getOptions(filterData.branch, 'productTypes', {'category': filterData.category}))
        }
    }, [filterData.category])

    useEffect(async() => {
        if(filterData.producttype != '' && filterData.category != ''){
            setProducts(await getOptions(filterData.branch, 'products', {'category': filterData.category, 'producttype': filterData.producttype}))
        }
    }, [filterData.producttype])

    return(
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <Row>
                            {
                                fordertype && 
                                <Col className={'mb-3'}>
                                    <label>Order Type</label>
                                    <select className="form-control" onChange={(e) => {setFilterData(prev => ({...prev, type: e.target.value}))}}>
                                    {
                                        reportWorkTypes?.map((v, i) => <option key={`WT_${i}`} value={v.id}>{v.title}</option>)
                                    }
                                    </select>
                                </Col>
                            }
                            {
                                fcity &&
                                <Col className={'mb-3'}>
                                    <label>Select City</label>
                                    <MultiSelect
                                        options={ cities === undefined ? [] : cities}
                                        value={filterData.city || []}
                                        onChange={(e) => {
                                            console.log(e.value)
                                            setMultiSelected(prev => ({...prev, city:e}))
                                            setFilterData(prev => ({...prev, city:e}))
                                        }}
                                        required={false}
                                        labelledBy="Select"
                                    />
                                </Col>
                            }
                            {
                                fclients && 
                                <Col className={'mb-3'}>
                                    <label>Select Client</label>
                                    <MultiSelect
                                        options={ clients === undefined ? [] : clients}
                                        value={filterData.client || []}
                                        onChange={(e) => {
                                            setMultiSelected(prev => ({...prev, client:e}))
                                            setFilterData(prev => ({...prev, client:e}))
                                        }}
                                        required={false}
                                        labelledBy="Select"
                                    />
                                </Col>
                            }
                            {
                                fdates &&
                                <Col className={'mb-3'}>
                                    <label>Date Range</label>
                                    <Flatpickr
                                        className="form-control d-block"
                                        placeholder="DD-MM-YYYY"
                                        options={{
                                            mode: "range",
                                            dateFormat: "d-m-Y",
                                            defaultDate: [
                                                moment().format("DD-MM-YYYY"), 
                                                moment().format("DD-MM-YYYY")
                                            ]
                                        }}
                                        onChange={
                                            (e) => {
                                                const dates = e.map(dateString => Timezone(dateString));
                                                setFilterData(prev => ({...prev, dates: dates}))
                                            }
                                        }
                                    />
                                </Col>
                            }
                            {
                                fp_categories && 
                                <Col className={'mb-4'}>
                                    <label>Product Category</label>
                                    <MultiSelect
                                        options={ categories === undefined ? [] : categories}
                                        value={filterData.category || [] }
                                        onChange={(e) => {
                                            setMultiSelected(prev => ({...prev, category:e}))
                                            setFilterData(prev => ({...prev, category:e}))
                                        }}
                                        required={false}
                                        labelledBy="Select"
                                    />
                                </Col>
                            }
                            {
                                fp_types &&
                                <Col className={'mb-3'}>
                                    <label>Product type</label>
                                    <MultiSelect
                                        options={ productTypes === undefined ? [] : productTypes}
                                        value={filterData.producttype || [] }
                                        onChange={(e) => {
                                            setMultiSelected(prev => ({...prev, producttype:e}))
                                            setFilterData(prev => ({...prev, producttype:e}))
                                        }}
                                        required={false}
                                        labelledBy="Select"
                                    />
                                </Col>
                            }
                            {
                                fproducts &&
                                <Col className={'mb-3'}>
                                    <label>Product</label>
                                    <MultiSelect
                                        options={ products === undefined ? [] : products}
                                        value={filterData?.product || []}
                                        onChange={(e) => {
                                            setMultiSelected(prev => ({...prev, product:e}))
                                            setFilterData(prev => ({...prev, product:e}))
                                        }}
                                        required={false}
                                        labelledBy="Select"
                                    />
                                </Col>
                            }
                            <Col md={1} style={{marginTop: '24px', width: '142px'}}>
                                <button className="btn btn-primary float-end" onClick={() => callback(filterData)} > Submit</button>
                                <button className="btn btn-primary float-end mdi mdi-refresh me-1" title="reset filters" onClick={() => setFilterData(initObj)} ></button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}