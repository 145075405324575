import axios from 'axios';
import { encryptData } from 'services/encription_service';

// const baseURL = "http://localhost/api/"
const baseURL = process.env.REACT_APP_BASEURL
const axiosApi = axios.create({baseURL: baseURL})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

const check = (e) => {
  if(e?.response?.status == 401){
    localStorage.clear()
    window.location.href = '/'
  }

  if(e?.response?.status == 500){
    return '500'
  }
}

export async function get(url, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = localStorage.getItem('token')
  return await axiosApi.get(url, { ...config }).then(
    async(response) => {
      if(process.env.REACT_APP_NODE_ENV == 'dev'){
      }
      return response.data
      // const decryptedData = await decryptData(response.data.response);
      // return {...response.data, response: response.data.response}
      // return {...response.data, response: JSON.parse(decryptedData)}
    }).catch(e => check(e))
}

export async function post(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = localStorage.getItem('token')

  return axiosApi
    .post(url, process.env.REACT_APP_NODE_ENV == 'prod' ? {...data} :[data=encryptData({ ...data })], { ...config })
    .then(async(response) => {
      if(process.env.REACT_APP_NODE_ENV == 'dev'){
      }
      return response.data
      // const decryptedData = await decryptData(response.data.response);
      // return {...response.data, response: JSON.parse(decryptedData)}
    })
}

export async function uploadfile(url, data) {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", localStorage.getItem('token'));

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: data,
    redirect: 'follow'
  };

  return fetch(`${baseURL}${url}`, requestOptions)
  .then(response => response.text())
  .then(result => JSON.parse(result))
}

export async function put(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = localStorage.getItem('token')
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = localStorage.getItem('token')
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
