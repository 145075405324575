import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
    Col,
    Container,
    Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Datatable from "components/Datatables/datatable";
import { post } from "helpers/api_helper";
import "react-datepicker/dist/react-datepicker.css";
import { Filters } from "../filters";
import Maincard from "../components/maincard";

const isType = 'Orders'
const styleSheet = {
    fontStyle: {
        fontWeight: 'bold',
        background: '#424141',
        padding: '0px 15px',
        color: '#fff'
    }
}

const OrderReport = () => {
    const [rowData, setRowData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [dateRange, setDateRange] = useState('')
    const [dataCounts, setDataCounts] = useState({
        total_amount: 0,
        total_orders: 0,
        new: 0,
        redo: 0,
        corr: 0,
    })
    // const [filters, setFilters] = useState({
    //     'search': null,
    //     'client': null,
    //     'worktype': null,
    //     'department': null,
    //     'category': null,
    //     'producttype': null,
    //     'product': null,
    //     'bydate': null,
    // });

    // const [filtersOptions, setFiltersOptions] = useState([]);
    // const [sizePerPage, setSizePerPage] = useState(10);
    // const [page, setPage] = useState(1);


    // table Column Data
    let tableColumns = [
        {
            dataField: 'id',
            text: '#',
            sort: true
        },{
            dataField: 'worktype',
            text: 'Order Type',
            // hidden: filterData.type == 'all'? false : true,
            formatter: (cell, row,  rowIndex, extraData) => {
                switch(row.worktype){
                    case 'new':
                        return <span style={styleSheet.fontStyle} className="bg-success">New</span>
                    case 'redo':
                        return <span style={styleSheet.fontStyle} className="bg-danger">Redo</span>
                    case 'corr':
                        return <span style={styleSheet.fontStyle} className="bg-warning">Correction</span>
                }
            }
        },{
            dataField: 'order_number',
            text: 'Order Number',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={styleSheet.fontStyle}>{row.order_number}</span>
        },{
            dataField: 'case_no',
            text: 'Case No',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={styleSheet.fontStyle}>{row.case_no}</span>
        },{
            dataField: 'client',
            text: 'Client',
            sort: true
        },{
            dataField: 'patient_name',
            text: 'Patient',
            sort: true
        },{
            dataField: 'order_date',
            text: 'Order Date',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <b>{row.order_date}</b>
        },{
            dataField: 'due_date',
            text: 'DueDate',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <b>{row.due_date}</b>
        },{
            dataField: 'order_value',
            text: 'Amount',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <b>{parseFloat(row.order_value).toFixed(2)}</b>
        }
    ];

    const calculateTotal = (data, index = 0) => {
        if (index >= data.length) return 0;
        const currentOrderValue = parseFloat(data[index]?.order_value) || 0;   
        return currentOrderValue + calculateTotal(data, index + 1);
    }

    const submitCall = async(filterData) => {
        setRowData(null)
        setIsLoading(true)
        setDateRange(filterData.dates.join('_').replace(/-/g, ''))

        const transformedData = {
            ...filterData,
            client: Array.isArray(filterData?.client) ? filterData.client.map(item => item.value) : [],
            category: Array.isArray(filterData?.category) ? filterData.category.map(item => item.value) : [],
            producttype: Array.isArray(filterData?.producttype) ? filterData.producttype.map(item => item.value) : [],
            product: Array.isArray(filterData?.product) ? filterData.product.map(item => item.value) : []
        };        

        const res = await post(`api/reports/orders`, transformedData);
        if(res.status == 200){
            if(res.response?.data.length > 0){
                setRowData(res.response);
                const worktypeCount = res.response?.data.reduce((acc, item) => {
                    acc[item.worktype] = (acc[item.worktype] || 0) + 1;
                    return acc;
                }, {});
                setDataCounts(prev => ({ ...prev, ...worktypeCount, total_orders: res.response?.data.length }));
                setDataCounts(prev => ({ ...prev, ...worktypeCount, total_amount: calculateTotal(res.response?.data || []).toFixed(2) }));
    
            }
            setIsLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>Dashboard | {isType}</title> </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Reports" breadcrumbItem={isType} />
                    <Filters callback={submitCall} />
                    <Maincard data={ dataCounts } loading={isLoading}/>

                    <Row style={{minHeight: '520px', overflowY: 'auto'}}>
                        <Col>
                            {
                                <Datatable
                                    keyField={'id'}
                                    title={``}
                                    isAdd={false}
                                    handleAddButton=''
                                    columns={tableColumns}
                                    rows={rowData?.data || []}
                                    isTableHead={true}
                                    loading={ isLoading }
                                    filename={`geneal_orders_${dateRange}`}
                                    rowsLength={rowData?.data?.length || 0}
                                    isSearch={true}
                                    is_remote={false}
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default OrderReport
