import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux";
import {
    Container,
    Row,
    Col
} from "reactstrap"
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { clientOptions } from "store/actions"
import "react-datepicker/dist/react-datepicker.css";
import { post, get } from "helpers/api_helper"
import Datatable from "./Datatables"
import moment from "moment";
import { Filters } from "./filters";

const isType = 'Invoices'
const ProformaReport = () => {
    const dispatch = useDispatch()
    const [rowData, setRowData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({
        'search': null,
        'client': null,
        'dates': [moment().format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')],
        'type': 0
    });
    
    useEffect(() => {
        dispatch(clientOptions({type: 'invoice&_gh=1'}))
    },[])

    // table Column Data
    let tableColumns = [
        {
            dataField: 'id',
            text: '#',
            sort: true
        },{
            dataField: 'invoice_number',
            text: 'Invoice Number',
            sort: true
        },{
            dataField: 'client',
            text: 'Client',
            sort: true
        },{
            dataField: 'invoice_date',
            text: 'Invoice Date',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => row.invoice_date
        },{
            dataField: 'invoice_total',
            text: 'Sub Total',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => (parseFloat(row.invoice_total)).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        },{
            dataField: 'actions1',
            text: 'GST (5%)',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => ((parseFloat(row.invoice_total) * 0.05)).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        },{
            dataField: 'actions2',
            text: 'Invoice Amount',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => Math.round(parseFloat(row.invoice_total) + (row.invoice_total * 0.05)).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        },{
            dataField: 'actions',
            text: "Actions",
            isDummyField: true,
            formatter: (cell, row,  rowIndex, extraData) => {
                return <>
                    <Link
                        target="_blank"
                        to={`/invoice/print?invoice_number=${row?.invoice_number}&type=proforma`}
                        title={"View invoice"}
                        className={`btn btn-outline-success btn-sm`} >
                        <i className={`fas fa-eye`} />
                    </Link>
                </>
            }
        }
    ];

    // const submitCall = async() => {
    //     setRowData(null)
    //     setIsLoading(true)
    //     const res = await post(`api/reports/invoices?limit=${sizePerPage}&page=${page}`, filters);
    //     if(res.status == 200){
    //         setRowData(res.response);
    //         setIsLoading(false)
    //     }
    // }    
    // useEffect(submitCall, [])
    
    // on table change value
    const submitCall = async(data = null) => {
        setFilters(p=> ({...p, ...data}));

        if(data?.page){
            setSizePerPage(data.sizePerPage)
            setPage(data.page)
        }

        // const queryParams = new URLSearchParams(filters).toString();
        const res = await post(`api/reports/invoices?q=${""}&limit=${data?.sizePerPage || sizePerPage}&page=${data?.page || page}`, filters);
        if(res.status == 200){
            setRowData(res.response);
            setIsLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>Dashboard | {isType}</title> </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Reports" breadcrumbItem={isType} />
                    <Filters
                        callback={submitCall} 
                        fcity={false}
                        fclients={true}
                        fdates={true}
                        fp_categories={false}
                        fp_types={false}
                        fproducts={false}
                        fordertype={false} />

                    <Row>
                        <Col>
                            <Datatable
                                keyField={'id'}
                                title={`Proforma Invoice Report`}
                                isAdd={false}
                                placeholder={'Search by client, order and patient '}
                                isSearch={true}
                                columns={tableColumns || []}
                                rows={rowData?.data || []}
                                rowsLength={parseInt(rowData?.count) || 0}
                                loading={isLoading}
                                ssr={submitCall}
                            />
                            {/* {
                                <Datatable
                                    keyField={'id'}
                                    title={`All ${isType}`}
                                    isAdd={false}
                                    handleAddButton=''
                                    columns={tableColumns}
                                    rows={rowData?.data || []}
                                    rowsLength={rowData?.count || 0}
                                    isTableHead={false}
                                    loading={ false }
                                    isSearch={true}
                                    ssr={filterOrderHandler}
                                />
                            } */}

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ProformaReport
