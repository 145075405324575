import './components.css'

const MaincardDynamic = ({data = [], label = '', loading, colSize = 'col-3'}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="align-self-center col-lg-8">
                                <label>{label}</label>
                                <div className="text-lg-center mt-4 mt-lg-0">
                                    <div className="row justify-center">
                                        {
                                            data?.map((v, i) => 
                                                <div key={`MCD_${i+1}_${v.value}`} className={colSize}>
                                                    <div className='maincard-border'>
                                                        <p className="text-muted text-truncate mb-2">{v.label}</p>
                                                        {
                                                            !loading ? <h5 className="mb-0">{parseFloat(v.value).toFixed(2)}</h5>: <h5 className="card-title placeholder-glow"><span className="placeholder col-6"></span></h5>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MaincardDynamic;