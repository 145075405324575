import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux";
import {
    Container,
    Row,
    Col
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Datatable from "components/Datatables/datatable"
import "react-datepicker/dist/react-datepicker.css";
import { post } from "helpers/api_helper"
import moment from "moment";
import MaincardDynamic from "../components/maincard_dynamic";
import { Filters } from "../filters";

const isType = 'Orderform Report'
const OrderformReport = () => {
    const dispatch = useDispatch()
    const [rowData, setRowData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [filters, setFilters] = useState({
        client: '',
        dates: [],
        page: 1,
        limit: 10,
    });

    let tableColumns = [
        {
            dataField: 'sr_no',
            text: '#',
            sort: true,
        },{
            dataField: 'orderform_number',
            text: 'Orderform Number',
            sort: true,
        },{
            dataField: 'order_id',
            text: 'Order No',
            sort: true,
        },{
            dataField: 'client',
            text: 'Client',
            formatter: (cell, row,  rowIndex, extraData) => <span>{row.clientname} ({row.client})</span>
        },{
            dataField: 'orderform_date',
            text: 'Orderform Date',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => moment(row.orderform_date).format('DD-MM-YYYY')
        },{
            dataField: 'order_value',
            text: 'Sub Total (₹)',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => parseFloat(row.order_value).toFixed(2)
        },{
            dataField: 'action',
            text: 'Total Order Value (₹)',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => (parseFloat(row.order_value) * 1.05).toFixed(2)
        }
    ];

    const [dateCounts, setDateCounts] = useState([
        {
            label: "Total Orders",
            value: 0
        },{
            label: "Total Clients",
            value: 0
        },{
            label: "Total Orderform Value",
            value: 0
        },{
            label: "Total Orderform Value With GST",
            value: 0
        }
    ])

    const fatchdata = async () => {
        setRowData(null)
        setIsLoading(true)
        const res = await post(`api/reports/orderforms`, filters);
        if(res.status == 200){
            setRowData(res.response);
            const temp = res.response;
            setDateCounts(p => [
                { ...p[0], value: temp?.total || 0 }, // Total Orders
                { ...p[1], value: temp?.total_clients || 0 }, // Total Clients
                { ...p[2], value: (temp?.orderformvalue || 0) }, // Total Orderform Value
                { ...p[3], value: (((temp?.orderformvalue) * 1.05) || 0) } // Total Orderform Value With GST
            ]);
            setIsLoading(false)
        }
    }

    // useEffect(() => {
    //     dispatch(clientOptions({type: 'challan&_gh=1'}))
    // },[])

    // useEffect(() => {
    //     fatchdata()
    // },[filters])

    // table Column Data

    const submitCall = async(filterData) => {
        setFilters(p => ({...p,  dates: filterData.dates.map(item => moment(item).format('YYYY-MM-DD'))}))
        fatchdata();
    }

    const filterOrderHandler = async(data) => {
        setFilters(p => ({...p, page: data.page, limit: data.sizePerPage}))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>Dashboard | {isType}</title> </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Reports" breadcrumbItem={isType} />
                    {/* <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                    <Col md={3}>
                                        <label>Select Client or Doctor</label>
                                        <Select
                                            styles={{
                                                menu: provided => ({ ...provided, zIndex: 9999 })
                                            }}
                                            onChange={(e) => {
                                                setFilters(p => ({...p, client: e?.value, clientOPT: e}))
                                            }}
                                            isClearable={true}
                                            value={filters?.clientOPT}
                                            options={DDOptions?.clients}
                                            name={'client'}
                                            required={false}
                                            placeholder={'Select Client'}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <label>By Month</label>
                                        <Select
                                            styles={{
                                                menu: provided => ({ ...provided, zIndex: 9999 })
                                            }}
                                            onChange={(e) => {
                                                setFilters(p => ({...p, month: e?.value, monthOpt: e}))
                                            }}
                                            isClearable={true}
                                            value={filters?.monthOpt}
                                            options={months}
                                            name={'month'}
                                            required={false}
                                            placeholder={'Select Month'}
                                        />
                                    </Col>
                                        <Col className="d-flex gap-2" md={1} style={{marginTop: '24px'}}>
                                            <Link
                                                target="_blank"
                                                to={`/reports/orderform-print?client=${filters?.client}&month=${filters?.month}`}
                                                title={"Print Order Forms"}
                                                className={`btn btn-success`}>Print</Link>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}

                    <Filters
                        callback={submitCall} 
                        fcity={false}
                        fclients={true}
                        fdates={true}
                        fp_categories={false}
                        fp_types={false}
                        fproducts={false}
                        fordertype={false} />
                    
                    <MaincardDynamic 
                        data={dateCounts} 
                        label={filters?.month == '' ? "Overall Information" : "Filtered Information"} 
                        loading={isLoading} 
                    />

                    <Row>
                        <Col>
                            {
                                <Datatable
                                    keyField={'id'}
                                    title={``}
                                    isAdd={false}
                                    handleAddButton=''
                                    columns={tableColumns}
                                    rows={rowData?.data || []}
                                    isTableHead={true}
                                    loading={ isLoading }
                                    filename={`geneal_orders`}
                                    rowsLength={rowData?.total || 0}
                                    ssr={filterOrderHandler}
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default OrderformReport
