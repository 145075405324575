import React, { useEffect, useState, useCallback } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Datatable from "components/Datatables/datatable";
import { deliveryModeOptions } from 'constants/layout';
import { get } from "helpers/api_helper";
import { CHALLAN_URL, ORDERFORM_URL } from 'helpers/url_helper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { CreateChallan, clientOptions } from "store/actions";
import Orderform from './orderform';
import debounce from 'lodash/debounce';

const isType = 'Create Orderform'
const styleSheet = {
    fontStyle: {
        fontWeight: 'bold',
        background: '#424141',
        padding: '0px 15px',
        color: '#fff'
    }
}

const NewChallan = () => {
    const dispatch = useDispatch()
    const { DDOptions } = useSelector(state => state.Master);
    const [orderNumber, setOrderNumber] = useState([]);
    const [clientId, setClientId] = useState(null);
    const [deliveryMode, setDeliveryMode] = useState(1);
    const [note, setNote] = useState('');
    const [sDate, setSDate] = useState(new Date());
    const [challans, setChallans] = useState(null);
    const [challansLoad, setChallansLoad] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);
    // const [tempData, setTempData] = useState(false);

    const getData = async() => {
        if(clientId && clientId !== undefined) {
            setOpenPrint(false)
            setChallansLoad(true)
            const res = await get(`${CHALLAN_URL}/${clientId.value}`);
            if(res.status == 200){
                setOrderNumber([])
                setChallansLoad(false)
                setChallans(res.response)
                // setTempData(<Orderform isCall={true} />)
            }
        }
    }

    const debouncedGetOrdersByNumber = useCallback(
        debounce((value) => {
          getOrdersByNumber(value);
        }, 600),
        []
    );

    useEffect(() => getData(),[clientId])
    // useEffect(() => setTempData(<Orderform isCall={true} />),[])

    const handleAddButton = () => {}

    /**
     * check All Box Handle
     */
    const isCheckboxAllCall = (e) => setOrderNumber(e.target.checked ? challans.map( v => v.order_number ) : [])

    /**
     * Single Check Box Handle
     */
    const isCheckboxCall = (order_number, isChecked) => {
        if(isChecked){
            setOrderNumber(e => [...e, order_number])
        }else{
            setOrderNumber(orderNumber.filter( item => item !== order_number))
        }
    }

    const selectCallFunction = (orderNumber, isChecked) => {
        if (isChecked) {
            console.log(`Checkbox for order ${orderNumber} is checked.`);
            // Add your logic for when the checkbox is checked
        } else {
            console.log(`Checkbox for order ${orderNumber} is unchecked.`);
            // Add your logic for when the checkbox is unchecked
        }
    };

    // table Column Data
    let tableColumns = [
        {
            dataField: 'id',
            text: 'Select', // Keep this as a string
            sort: false,
            isDummyField: true,
            formatExtraData: orderNumber,
            formatter: (cell, row, rowIndex, extraData) => (
                <input 
                    type="checkbox" 
                    checked={orderNumber.includes(row.order_number)} 
                    onChange={(e) => {
                        isCheckboxCall(row.order_number, e.target.checked); // Call your function here
                        selectCallFunction(row.order_number, e.target.checked); // Call your additional function
                    }} 
                />
            )
        },{
            dataField: 'order_number',
            text: 'Order Number',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={styleSheet.fontStyle}>{row.order_number}</span>
        },{
            dataField: 'case_no',
            text: 'Case No',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={styleSheet.fontStyle}>{row.case_no}</span>
        },{
            dataField: 'clientname',
            text: 'Client',
            sort: true
        },{
            dataField: 'patient_name',
            text: 'Patient',
            sort: true
        },{
            dataField: 'order_date',
            text: 'Order Date',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <b>{row.order_date}</b>
        },{
            dataField: 'title',
            text: 'Products ',
            sort: true,
            // formatter: (cell, row,  rowIndex, extraData) => <b>{row.due_date}</b>
        },{
            dataField: 'order_value',
            text: 'Amount',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <b>{parseFloat(row.order_value).toFixed(2)}</b>
        }
    ];

    useEffect(() => {
        // Load Clients
        dispatch(clientOptions({type: 'challan'}))
    },[])

    const submitCall = () => {
        const callback = (data) => {
            setChallans(null);
            setClientId(null);
            setOrderNumber(null)
            setNote('')
            window.open(`/orderform/print?_orderform=${data?.orderform?.orderform_number}`, '_blank');
        }

        let obj = {
            'clientId' : clientId?.value,
            'deliveryMode' : deliveryMode,
            'note' : note,
            'orders' : orderNumber,
            'date' : sDate
        }
        dispatch(CreateChallan({data: {url: ORDERFORM_URL, Obj:obj}, callback}));
    }

    useEffect(() => {
        if(localStorage.getItem('printdata') && openPrint){
            window.open(`/challan/print?cno=${openPrint}`);
        }
    }, [openPrint])

    const getOrdersByNumber = async(orderNumber) => {
        if(orderNumber) {
            setOpenPrint(false)
            setChallansLoad(true)
            const res = await get(`${CHALLAN_URL}/order-by/${orderNumber}`);

            if(res.status == 200 && res.response?.total_rows){
                // setClientId(...DDOptions?.clients.filter(v => v.value == res.response[0]['client_id']))
                setOrderNumber([])
                setChallansLoad(false)
                setChallans(res.response)
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>{isType} | KS-Lab</title> </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Masters" breadcrumbItem={isType} />
                    
                    <Row>
                        <Col>
                            <Card className="mb-2">
                                <CardBody>
                                    <Row>
                                        <Col className={'mb-3'}>
                                            <label>Select Client</label>
                                            <Select
                                                styles={{
                                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                                }}
                                                onChange={(e) => {
                                                    setClientId(e)
                                                }}
                                                isClearable={true}
                                                value={clientId}
                                                options={DDOptions?.clients}
                                                name={'docs'}
                                                required={false}
                                                placeholder={'Select Doctor'}
                                            />
                                        </Col>
                                        <Col className={'mb-3'}>
                                            <label>By Order Number or Modal Number</label>
                                            <input className="form-control" placeholder={'By Order Number or Modal Number'} onChange={(e) => debouncedGetOrdersByNumber(e.target.value)}/>
                                        </Col>
                                        <Col className={'mb-3'}>
                                            <label>Orderform Date</label>
                                            <DatePicker className="form-control" selected={sDate} onChange={(date) => setSDate(date)} />
                                        </Col>
                                        <Col className={'mb-3'}>
                                            <label>Delivery Mode</label>
                                            <select className="form-control" onChange={(e) => {setDeliveryMode(e.target.value)}}>
                                                {deliveryModeOptions?.map((v, i) => <option key={`DM_${v.value}_${i}`} value={v.value}>{v.label}</option> ) }
                                            </select>
                                        </Col>
                                        <Col className={'mb-3'}>
                                            <label>Note</label>
                                            <input className="form-control" placeholder={'Note'} onChange={(e) => setNote(e.target.value)}/>
                                        </Col>
                                        <Col md={1} style={{marginTop: '24px'}}>
                                            <button className="btn btn-primary float-end" onClick={() => submitCall()} disabled={orderNumber?.length > 0 ? false:true}> <i className="fa fa-plus" /> Create</button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row style={{overflowY: 'auto'}}>
                        <Col>
                            { challans?.total_rows > 0 && 
                                <Datatable
                                    keyField={'id'}
                                    title={`All ${isType}`}
                                    // handleAddButton={handleAddButton}
                                    columns={tableColumns}
                                    rows={challans?.rows || []}
                                    rowsLength={challans?.total_rows || []}
                                    isAdd={false}
                                    isTableHead={false}
                                    loading={challansLoad}
                                    isCheckbox={true}
                                />
                            }
                        </Col>
                    </Row>

                    <Orderform isCall={true} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default NewChallan
